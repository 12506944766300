import { ReactNode, useCallback, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import neonGreenArrow from '../../assets/neon-green-arrow-icon.svg'
import openfarmLogo from '../../assets/openfarm-logo.png'
import { useAuth } from '../../contexts/AuthContext'
import { useSpecies } from '../../contexts/SpeciesContext'
import api from '../../services/api'
import UserPointsDesktop from '../UserPointsDesktop'
import * as S from './style'

interface HeaderProps {
    element?: ReactNode
    borderBottom?: boolean
    logo?: boolean
    pageTitle?: string
    showDesktopWidgets?: boolean
}

function Header ({ element, borderBottom, pageTitle, logo, showDesktopWidgets }:HeaderProps ) {
    const [benefitLink, setBenefitLink] = useState<string>('')
    const [reinvestLink, setReinvestLink] = useState<string>('')
    const { userCan } = useAuth()
    const { species, changeSpecie, specieSelected, userHasGoldBox } = useSpecies()

    const handleReinvestLink = useCallback(async () => {
        if (!specieSelected) return
        const response = await api.get(`/cargill-catalogo/${specieSelected.catalog}`)
        setReinvestLink(response.data.url)
    }, [setReinvestLink, specieSelected])


    const handleBenefitsLink = useCallback(async () => {
        const response = await api.get(`/cargill-catalogo/81544`)
        setBenefitLink(response.data.url)
    }, [])

    useEffect(() => {
        handleReinvestLink()
        handleBenefitsLink()
    }, [handleBenefitsLink, handleReinvestLink])

    return <S.Header $hasBorderBottom={borderBottom}>
        <S.HeaderElement>
            {element}
        </S.HeaderElement>
        {pageTitle?.length && <h2>{pageTitle}</h2>}
        {logo && <Link to="/inicio"><img src={openfarmLogo} alt="Logotipo do produto Openfarm" /></Link>}
        <S.DesktopLogo to="/inicio">
            <img src={openfarmLogo} alt="Logotipo do produto Openfarm" />
        </S.DesktopLogo>
        {showDesktopWidgets && <S.DesktopNav>
            <li>
                <Link to='/inicio'>Início</Link>
            </li>
            {userCan('read:wallet') && <li>
                <Link to='/carteira'>Carteira</Link>
            </li>}
            {(userCan('read:reinvest-catalog') || userCan('read:benefits-catalog')) && <li className='dropdown-trigger'>
                <div className='dropdown-title'>
                    Loja
                    <img src={neonGreenArrow} alt="Ícone de seta verde, indicando um menu expansível" />
                </div>
                <div className='dropdown-content'>
                    {userCan('read:reinvest-catalog') && <Link to={reinvestLink}>Catálogo Reinvestimento</Link>}
                    {userCan('read:benefits-catalog') && <Link to={benefitLink}>Catálogo Benefício</Link>}
                </div>
            </li>}
            {userHasGoldBox && <li>
                <Link to='/gold-box'>Gold Box</Link>
            </li>}
            <li>
                <Link to='/blog'>Blog</Link>
            </li>
            <li className='dropdown-trigger'>
                <div className='dropdown-title'>
                    Espécies
                    <img src={neonGreenArrow} alt="Ícone de seta verde, indicando um menu expansível" />
                </div>
                <div className='dropdown-content'>
                    {species.map(specie => (
                        <button key={specie.id} onClick={() => changeSpecie(specie.id)}>
                            <S.SelectSpeciesModalContentButtonImage>
                                <img src={specie.icon} alt={'Ícone representando a categoria ' + specie.name} />
                            </S.SelectSpeciesModalContentButtonImage>
                            {specie.name}
                        </button>
                    ))}
                </div>
            </li>
        </S.DesktopNav>}
        {showDesktopWidgets && <S.UserPointsDesktopWidget>
            <UserPointsDesktop />
        </S.UserPointsDesktopWidget>}
    </S.Header>
}

export default Header