import styled from 'styled-components';

export const TextAreaFloatingLabel = styled.div<{ $isActive: boolean }>`
    border: 1px solid #88A8A4;
    border-radius: 28px;
    padding: ${({ $isActive }) => ($isActive ? '26px 24px 10x 24px' : '19px 24px')};
    width: 100%;
    margin-bottom: 24px;
    position: relative;
    height: 170px;
    h3 {
        position: absolute;
        color: var(--black);
        font-size: ${({ $isActive }) => ($isActive ? '0.75rem' : '1rem')};
        top: ${({ $isActive }) => ($isActive ? '10px' : '19px')};
        left: 24px;
        transition: all 0.3s ease-in-out;
        font-weight: normal;
    }

    textarea {
        color: var(--black);
        font-size: 1rem;
        width: ${({ $isActive }) => ($isActive ? '80%' : '100%')};
        font-family: ${({ $isActive }) => ($isActive ? `var(--font-bold)` : `'Gilroy-Medium',sans-serif`)};
        margin-top: ${({ $isActive }) => ($isActive ? '26px' : '1px')};
        margin-left: ${({ $isActive }) => ($isActive ? '24px' : '0px')};
        border-radius: ${({ $isActive }) => ($isActive ? '0px' : '28px')};
        z-index: 10;
        position: relative;
        background: transparent;
        height: ${({ $isActive }) => ($isActive ? 'auto' : '50px')};
    }
`;